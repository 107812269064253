import API from "../Utils/API";
export const getFamiliesByNativeForVolunterAction = () => (dispatch) => {
  dispatch({ type: "GET_FAMILIES_BY_NATIVE_FOR_VOLUNTEER_REQUEST" });
  API.get("api/families_by_native_for_volunteer")
    .then((response) => {
      dispatch({
        type: "GET_FAMILIES_BY_NATIVE_FOR_VOLUNTEER_SUCCESS",
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: "GET_FAMILIES_BY_NATIVE_FOR_VOLUNTEER_FAILURE",
       
        payload: error.response.data,
      });
    });
};
