import constants from "../Utils/constants";
const initialState = {
  allCommunitiesNativeVolunteer: [],
  isCreateNativeVolunteerLoading: false,
  isGetNativeVolunteerLoading: false,

  isDeleteNativeVolunteerLoading: false,
  isVerifiedByVolunteerLoading: false,
};

export const nativeVolunteerReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_NATIVE_VOLUNTEER_REQUEST:
      return {
        ...state,

        isGetNativeVolunteerLoading: true,
      };

    case constants.GET_NATIVE_VOLUNTEER_SUCCESS:
      return {
        ...state,
        isGetNativeVolunteerLoading: false,
        allCommunitiesNativeVolunteer: action.payload,
      };
    case constants.GET_NATIVE_VOLUNTEER_FAILURE:
      return {
        ...state,
        isGetNativeVolunteerLoading: false,
      };
    case constants.CREATE_NATIVE_VOLUNTEER_REQUEST:
      return {
        ...state,
        isCreateNativeVolunteerLoading: true,
      };

    case constants.CREATE_NATIVE_VOLUNTEER_SUCCESS:
      return {
        ...state,
        isCreateNativeVolunteerLoading: false,
      };
    case constants.CREATE_NATIVE_VOLUNTEER_FAILURE:
      return {
        ...state,
        isCreateNativeVolunteerLoading: false,
      };
    case constants.DELETE_NATIVE_VOLUNTEER_REQUEST:
      return {
        ...state,
        isDeleteNativeVolunteerLoading: true,
      };

    case constants.DELETE_NATIVE_VOLUNTEER_SUCCESS:
      return {
        ...state,
        isDeleteNativeVolunteerLoading: false,
      };
    case constants.DELETE_NATIVE_VOLUNTEER_FAILURE:
      return {
        ...state,
        isDeleteNativeVolunteerLoading: false,
      };
    case constants.UPDATE_VERIFIED_BY_VOLUNTEER_REQUEST:
      return {
        ...state,
        isVerifiedByVolunteerLoading: true,
      };
    case constants.UPDATE_VERIFIED_BY_VOLUNTEER_SUCCESS:
     
    return {
        ...state,
        isVerifiedByVolunteerLoading: false,
      };
    case constants.UPDATE_VERIFIED_BY_VOLUNTEER_FAILURE:
      return {
        ...state,
        isVerifiedByVolunteerLoading: false,
      };
    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
