import constants from "../Utils/constants";
const initialState = {
  //   isGetTicketsLoading: false,
  isCreateMessageLoading: false,

  isDeleteFileLoading: false,

  //   isDeleteTICKETSLoading: false,
  //   isUpdateTICKETSLoading: false,
  //   allTickets: [],
};

export const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    // case constants.GET_TICKETS_REQUEST:
    //   return {
    //     ...state,
    //     isGetTicketsLoading: true,
    //     allTickets: [],
    //   };
    // case constants.GET_TICKETS_SUCCESS:
    //   return {
    //     ...state,
    //     isGetTicketsLoading: false,
    //     allTickets: action.payload,
    //   };
    // case constants.GET_TICKETS_FAILURE:
    //   return {
    //     ...state,
    //     isGetTicketsLoading: false,
    //   };
    case constants.CREATE_MESSAGES_REQUEST:
      return {
        ...state,
        isCreateMessageLoading: true,
      };
    case constants.CREATE_MESSAGES_SUCCESS:
      return {
        ...state,
        isCreateMessageLoading: false,
      };
    case constants.CREATE_MESSAGES_FAILURE:
      return {
        ...state,
        isCreateMessageLoading: false,
      };

    case constants.DELETE_FILE_REQUEST:
      return {
        ...state,
        isDeleteFileLoading: true,
      };
    case constants.DELETE_FILE_SUCCESS:
      return {
        ...state,
        isDeleteFileLoading: false,
      };
    case constants.DELETE_FILE_FAILURE:
      return {
        ...state,
        isDeleteFileLoading: false,
      };

    // case constants.UPDATE_TICKETS_REQUEST:
    //   return {
    //     ...state,
    //     isUpdateTICKETSLoading: true,
    //   };
    // case constants.UPDATE_TICKETS_SUCCESS:
    //   return {
    //     ...state,
    //     isUpdateTICKETSLoading: false,
    //   };
    // case constants.UPDATE_TICKETS_FAILURE:
    //   return {
    //     ...state,
    //     isUpdateTICKETSLoading: false,
    //   };

    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
