const initialState = {};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SAVING_USER_CREDENTIALS":
      return {
        ...state,
        user: action.payload.user,
        isAuthenticated: action.payload.isAuthenticated,
      };
    case "REMOVING_USER_CREDENTIALS":
      return {
        ...state,
        user: {},
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

export default authReducer;
