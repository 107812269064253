import API from "../Utils/API";
export const createFamilyAddressAction =
  (data, successCallback) => (dispatch) => {
    dispatch({ type: "CREATE_FAMILY_ADDRESS_REQUEST", payload: data });
    API.post("api/family_addresses", data)
      .then((response) => {
        dispatch({
          type: "CREATE_FAMILY_ADDRESS_SUCCESS",
          payload: response.data,
        });
        successCallback();
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_FAMILY_ADDRESS_FAILURE",
          payload: error.response.data,
        });
      });
  };

export const getFamilyAddress = (familyId) => (dispatch) => {
  dispatch({ type: "GET_FAMILY_ADDRESS_REQUEST" });
  API.get(`api/family_addresses?family_id=${familyId}`)
    .then((response) => {
      dispatch({ type: "GET_FAMILY_ADDRESS_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: "GET_FAMILY_ADDRESS_FAILURE",
        payload: error.response.data,
      });
    });
};

export const updateFamilyAddressAction =
  (data, successCallback) => (dispatch) => {
    dispatch({ type: "UPDATE_FAMILY_ADDRESS_REQUEST" });
    API.put(`api/family_addresses/${data.id}`, data)
      .then((response) => {
        dispatch({
          type: "UPDATE_FAMILY_ADDRESS_SUCCESS",
          payload: response.data,
        });
        successCallback();
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_FAMILY_ADDRESS_FAILURE",
          payload: error.response.data,
        });
      });
  };

export const deleteFamilyAddressAction =
  (data, successCallback) => (dispatch) => {
    dispatch({ type: "DELETE_FAMILY_ADDRESS_REQUEST" });
    API.delete(`api/family_addresses/${data}`)
      .then((response) => {
        dispatch({
          type: "DELETE_FAMILY_ADDRESS_SUCCESS",
          payload: response.data,
        });
        successCallback();
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_FAMILY_ADDRESS_FAILURE",
          payload: error.response.data,
        });
      });
  };
