import constants from "../Utils/constants";
const initialState = {
  isDeleteUserFamilyLoading: false,
};

export const userFamilyReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.DELETE_USER_FAMILY_REQUEST:
      return {
        ...state,
        isDeleteUserFamilyLoading: true,
      };
    case constants.DELETE_USER_FAMILY_SUCCESS:
     
    return {
        ...state,
        isDeleteUserFamilyLoading: false,
      };
    case constants.DELETE_USER_FAMILY_FAILURE:
      return {
        ...state,
        isDeleteUserFamilyLoading: false,
      };

    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
