import constants from "../Utils/constants";
const initialState = {
  allPublicActivity: [],
  isAllPublicActivityLoading: false,
};

export const publicActivityReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_PUBLIC_ACTIVITY_REQUEST:
      return {
        ...state,
        isAllPublicActivityLoading: true,
      };
    case constants.GET_PUBLIC_ACTIVITY_SUCCESS:
      return {
        ...state,
        isAllPublicActivityLoading: false,
        allPublicActivity: action.payload,
      };
    case constants.GET_PUBLIC_ACTIVITY_FAILURE:
      return {
        ...state,

        isAllPublicActivityLoading: false,
      };
   
      case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default publicActivityReducer;
