import API from "../Utils/API";
export const deleteUserFamily = (data, successCallback) => (dispatch) => {
  dispatch({ type: "DELETE_USER_FAMILY_REQUEST" });
  API.delete(`api/user_families/${data}`)
    .then((response) => {
      dispatch({ type: "DELETE_USER_FAMILY_SUCCESS", payload: response.data });
      successCallback();
    })
    .catch((error) => {
      dispatch({
        type: "DELETE_USER_FAMILY_FAILURE",
        payload: error.response.data,
      });
    });
};
