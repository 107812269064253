import constants from "../Utils/constants";
const initialState = {
  isCreateFamilyAddressLoading: false,
  isGetFamilyAddressLoading: false,
  isUpdateFamilyAddressLoading: false,
  isDeleteFamilyAddressLoading: false,
  allFamilyAddress: [],
};

export const familyAddressReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CREATE_FAMILY_ADDRESS_REQUEST:
      return {
        ...state,
        isCreateFamilyAddressLoading: true,
      };
    case constants.CREATE_FAMILY_ADDRESS_SUCCESS:
      return {
        ...state,
        isCreateFamilyAddressLoading: false,
      };
    case constants.CREATE_FAMILY_ADDRESS_FAILURE:
      return {
        ...state,
        isCreateFamilyAddressLoading: false,
      };
    case constants.GET_FAMILY_ADDRESS_REQUEST:
      return {
        ...state,
        isGetFamilyAddressLoading: true,
      };
    case constants.GET_FAMILY_ADDRESS_SUCCESS:
      return {
        ...state,
        isGetFamilyAddressLoading: false,
        allFamilyAddress: action.payload,
      };
    case constants.GET_FAMILY_ADDRESS_FAILURE:
      return {
        ...state,
        isGetFamilyAddressLoading: false,
      };
    case constants.UPDATE_FAMILY_ADDRESS_REQUEST:
      return {
        ...state,
        isUpdateFamilyAddressLoading: true,
      };
    case constants.UPDATE_FAMILY_ADDRESS_SUCCESS:
      return {
        ...state,
        isUpdateFamilyAddressLoading: false,
      };
    case constants.UPDATE_FAMILY_ADDRESS_FAILURE:
      return {
        ...state,
        isUpdateFamilyAddressLoading: false,
      };
    case constants.DELETE_FAMILY_ADDRESS_REQUEST:
      return {
        ...state,
        isDeleteFamilyAddressLoading: true,
      };
    case constants.DELETE_FAMILY_ADDRESS_SUCCESS:
      return {
        ...state,
        isDeleteFamilyAddressLoading: false,
      };
    case constants.DELETE_FAMILY_ADDRESS_FAILURE:
     
    return {
        ...state,
        isDeleteFamilyAddressLoading: false,
      };
    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
